import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "「とりまわかる TTS」というお話",
  "date": "2018-10-19T02:49:55.000Z",
  "slug": "entry/2018/10/19/114955",
  "tags": ["medley"],
  "hero": "./2018_10_19.png",
  "heroAlt": "tts"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、開発本部の宮内です。先日のメドレーの社内勉強会「TechLunch」で、「とりまわかる TTS」と題して Web Speech API のお話をしました。`}</p>
    <h1>{`Web Speech API とは？`}</h1>
    <p>{`macOS に、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`say`}</code>{`というコマンドがあるのはご存知でしょうか？ このコマンドは引数で受け取った文字列を発音してくれるというコマンドです。`}</p>
    <p>{`ターミナルアプリを開いて、次のようなコマンドを入力してみてください。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}>{`say -v Kyoko `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ご用件は何でしょう？"`}</span></code></pre></div>
    <p>{`このようにテキストから人間の声のように発音させる仕組みを`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E9%9F%B3%E5%A3%B0%E5%90%88%E6%88%90"
      }}>{`音声合成`}</a>{`といいます。`}</p>
    <p>{`このような音声合成や`}<a parentName="p" {...{
        "href": "https://ja.wikipedia.org/wiki/%E9%9F%B3%E5%A3%B0%E8%AA%8D%E8%AD%98"
      }}>{`音声認識`}</a>{`に関しては macOS 以外にも`}<a parentName="p" {...{
        "href": "https://aws.amazon.com/jp/transcribe/"
      }}>{`Amazon Transcribe`}</a>{`や`}<a parentName="p" {...{
        "href": "https://cloud.google.com/speech-to-text/docs/reference/rest/?hl=ja"
      }}>{`Google Cloud Speech API`}</a>{`などのクラウドサービスや Android では`}<a parentName="p" {...{
        "href": "https://developer.android.com/reference/android/speech/tts/TextToSpeech"
      }}>{`TextToSpeech クラス`}</a>{`という API が用意されていたりもします。`}</p>
    <p>{`今回の発表で使った`}<a parentName="p" {...{
        "href": "https://w3c.github.io/speech-api/"
      }}>{`Web Speech API`}</a>{`は、ブラウザでこれらの音声合成・認識を行うための API 仕様です。`}</p>
    <h1>{`実際に試してみる`}</h1>
    <p>{`音声合成の`}<a parentName="p" {...{
        "href": "https://miyucy.github.io/tts.html"
      }}>{`サンプルページ`}</a>{`を作りましたので、サンプルプログラムを抜粋して使い方の説明をしていきます。`}</p>
    <h2>{`voiceschanged イベント内で利用可能な音声を取得する`}</h2>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`constspeechSynthesis `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` window`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`speechSynthesis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

speechSynthesis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"voiceschanged"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`buildVoiceOption`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$voices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` speechSynthesis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getVoices`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`window.speechSynthesis.getVoices`}</code>{`関数を使うと利用可能な音声の一覧が取得できます。`}</p>
    <p>{`ただし、ページロード直後ですと、タイミングによっては空配列が帰ってくることがあります。 そのため、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`voiceschanged`}</code>{`イベントを受け取ってから、`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`window.speechSynthesis.getVoices`}</code>{`関数を呼び出すことによって、確実に実行できるようにしています。`}</p>
    <p>{`返却されてくる voice は仕様としてはユーザエージェントとブラウザの場合だとローカルに用意されている音声の種類で決ってくることになっています。`}</p>
    <p>{`macOS の日本語であれば以下のような種類の voice が返ってきます。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20181019/20181019115926.png",
        "alt": "f:id:medley_inc:20181019115926p:plain",
        "title": "f:id:medley_inc:20181019115926p:plain"
      }}></img></p>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`buildVoiceOption`}</code>{`は利用する音声を select タグで選択できるように設定する関数なので割愛します。`}</p>
    <h2><a parentName="h2" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/SpeechSynthesisUtterance"
      }}>{`SpeechSynthesisUtterance`}</a>{`で音声の設定をしていく`}</h2>
    <p>{`SpeechSynthesisUtterance とは以下のような働きをする API になります。`}</p>
    <blockquote>
      <p parentName="blockquote">{`Web Speech API の SpeechSynthesisUtterance インターフェイスは、音声要求を表現します。 これには、音声サービスが読むべき内容とその読み方に関する情報(例えば、言語、ピッチ、音量など)が含まれています。`}</p>
      <p parentName="blockquote"><a parentName="p" {...{
          "href": "https://developer.mozilla.org/ja/docs/Web/API/SpeechSynthesisUtterance"
        }}>{`https://developer.mozilla.org/ja/docs/Web/API/SpeechSynthesisUtterance`}</a></p>
    </blockquote>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`SpeechSynthesis`}</code>{`を使って実際に音声を発音させるにはこの API を使ってどのように発音させるのか？を設定していく必要があります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "javascript"
    }}><pre parentName="div" {...{
        "className": "language-javascript"
      }}><code parentName="pre" {...{
          "className": "language-javascript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`function`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setVoice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`utterance`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` voices`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` voiceName`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  constchoices `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` voices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`filter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`voice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` voice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`===`}</span>{` voiceName`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`choices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`length `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`voice `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` choices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` defaultVoice `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` voices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`find`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`voice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` voice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`default`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`defaultVoice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
      utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`voice `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` defaultVoice`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

$form`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`addEventListener`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"submit"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token parameter"
          }}>{`event`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=>`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  event`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`preventDefault`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`const`}</span>{` utterance `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`SpeechSynthesisUtterance`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`$textarea`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`value`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token function"
          }}>{`setVoice`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
    utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    speechSynthesis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getVoices`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
    $voices`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`selectedOptions`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`dataset`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`name
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pitch `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` $pitch`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`valueAsNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`rate `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` $rate`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`valueAsNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`volume `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` $volume`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`valueAsNumber`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  speechSynthesis`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`speak`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`utterance`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`フォームがサブミットされたら `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SpeechSynthesisUtterance`}</code>{`クラスのインスタンスを作成し、 それを引数に`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`speechSynthesis.speak`}</code>{`関数を呼び出せば、テキストボックスに入力されたテキストと`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`SpeechSynthesisUtterance`}</code>{`で設定したピッチ、音量などを元に設定された音声が出力されるようにしています。`}</p>
    <p>{`（サンプルプログラムではエラー処理を省いているため、ピッチや音量の調整スライダーで極端な値を指定すると、正しく音声が出力されないことがあります）`}</p>
    <p>{`今回の記事では紹介していませんが、Web Speech API にはもうひとつ`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/ja/docs/Web/API/SpeechRecognition"
      }}>{`SpeechRecognition API`}</a>{`があり、こちらは音声認識をブラウザでできるようにする API になっています。興味があればぜひ調べてみてください。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`今回は Web Speech API の音声合成のさわりを紹介しましたが、ご覧のとおりとても簡単に使うことができます。`}</p>
    <p>{`例えば、ブラウザの内容の読み上げをしてアクセシビリティを高めたりなどの使い方や、読みが難しい専門用語の発音を聞かせるようにするなど色々な使いかたが考えられるのではないでしょうか？`}</p>
    <p>{`この記事をご覧のみなさんもちょっと忘れかけられた、この API を使ってみてはいかがでしょう？`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      